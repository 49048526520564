body {
  padding-bottom: 30px;
  position: relative;
  min-height: 100vh;
  overflow-y: scroll;
  font-family: "Cairo", sans-serif !important;
}
.booking-table {
  padding: 10px;
  background-color: #fff;
}

.booking-table-content {
  padding: 10px;
}
.table-header {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #7e858e;
}
.filter-box {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #d7dfe9;
  border-radius: 2px;
}
.react-datepicker-wrapper {
  width: auto !important;
}
.modal-body.yn-modal {
  background: #fff !important;
}
.alert {
  padding: 0px !important;
}
.custom-control-input {
  position: absolute;
  top: 7px;
  right: 11px;
}
.rdp-months {
  background: #fff;
  position: absolute;
  z-index: 8;
  border-radius: 8px;
  padding: 6px;
}
.PhoneInputInput {
  border: none;
}

.PhoneInputInput:hover input:focus {
  border: none;
}
.DayPicker {
  background-color: #fff !important;
  position: absolute;
  z-index: 1;
  font-size: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.alert-heading {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700 !important;
  padding-left: 15px !important;
  margin: 0 !important;
  font-size: 18px !important;
  line-height: 45px !important;
  /* identical to box height */
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.95rem 1rem;
}

.send-email-header {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 45px;
  /* identical to box height */
  color: #1e2432;
}
.booking-table-text {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3f4247;
}
.booking-table-subtext {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #7a7e84;
}
.table-option-header {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  color: #3f4247;
}
.options-table {
  width: 833px;
}
.secondry-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #0b69ff;
}
.react-datepicker__input-container {
  width: auto;
}
.react-datepicker__input-container input {
  background: #ffffff;
  width: 90%;
  height: 40px;
  text-align: left;
  padding-left: 10px;
  border: 1px solid #d7dfe9;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.options-table .input {
  background: #ffffff;
  width: 80%;
  height: 30px;
  text-align: center;
  border: 1px solid #d7dfe9;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.coupon-flag:hover + .coupon-flag-name {
  display: block;
}
.coupon-flag-name {
  display: none;
  position: absolute;
  background-color: #fff;
  padding: 8px;
  top: 25px;
  width: max-content;
  z-index: 99;
  border-radius: 4px;
  font-size: 14px;
}

.custom-modal-width .modal-content {
  width: 480px !important;
  border: 1px solid #f4f4f4 !important;
  box-sizing: border-box;
  height: auto;
  border-radius: 4px !important;
}
.pagination-page {
  height: 34px;
  cursor: pointer;
  width: 34px;
  border-radius: 50%;
}
.dd-custom-menu {
  cursor: pointer;
  background-color: #fff;
  position: absolute;
  border: 1px solid #d7dfe9;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 10px;
  z-index: 99;
  right: 43px;
  padding: 10px;
  z-index: 99;
}
.dd-custom-toggle.dropdown-toggle {
  border: none !important;
  background-color: #fff !important;
}
.dd-custom-toggle.dropdown-toggle::after {
  content: none !important;
}

.dd-custom-items.dropdown-item:hover,
.dd-custom-items.dropdown-item:focus {
  background-color: #0d6efd !important;
  color: #fff !important;
}

.pagination_con {
  height: 38px;
  width: auto;
  padding: 15px;
  border-radius: 19px;
  background-color: #ffffff;
}
.page_selected {
  height: 34px;
  width: 34px;
  color: #fff;
  background-color: #0d6efd;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(11, 19, 54, 0.16);
}
.options-table .input:focus {
  text-align: center;
}
.option-table td {
  height: 40px;
  padding: 0 !important;
  vertical-align: middle;
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3f4247;

  text-align: center !important;
}
tbody .option-table {
  border: 1px solid rgba(126, 133, 142, 0.4) !important;
}
/* CSS used here will be applied after bootstrap.css */

/* custom checkboxes */

/***** RADIO BUTTON STYLES *****/
.rdio {
  position: relative;
  input[type="radio"] {
    opacity: 0;
  }
  span {
    padding-left: 8px;
    cursor: pointer;
    &:before {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 4px;
      left: -1px;
      content: "";
      display: inline-block;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      border: 1px solid #299cf7;
      background: #fff;
    }
  }
  input[type="radio"] {
    margin: 0px;
    &:disabled + span {
      color: #999;
      &:before {
        background-color: #299cf7;
      }
    }
    &:checked + span::after {
      content: "";
      position: absolute;
      top: 8px;
      left: 3px;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #299cf7;
      -moz-border-radius: 50px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
    }
  }
}

.rdio-default input[type="radio"]:checked + span:before {
  border-color: #299cf7;
}

.rdio-primary input[type="radio"]:checked + span {
  &:before {
    border-color: #299cf7;
  }
  &::after {
    background-color: #299cf7;
  }
}
.search-icon {
  position: absolute;
  padding: 8px 14px;
}
.dd-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #3f4247;
}
.menu-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  color: #1f2830;
}

.dashboard-header {
  padding: 32px 40px;
  width: 100%;
  background: rgba(232, 233, 245, 0.25);
  border-radius: 8px;
}
.dashboard-card {
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid #e8e9f5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 24px;
}
.dashboard-subtext {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #676767;
}
.dashboard-text {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  color: #333333;
  margin-bottom: 8px;
}

.menu-text:hover {
  background-color: #1c95f800 !important;
}
.logo {
  transition: 1s ease;
}
.search-input {
  width: 392px !important;
  border: 1px solid #d7dfe9 !important;
  border-radius: 4px !important;
  padding-left: 2.3rem;
  margin-right: 16px;
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  height: 40px !important;
  font-size: 14px;
  color: #3f4247;
}
.radio-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #676767;
}
.search-input::placeholder {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #99a5b4;
}
.css-yk16xz-control {
  border: 1px solid #d7dfe9 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 24px rgba(232, 233, 245, 0.5) !important;
}
.tab-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.age-tab-con {
  display: flex;
  flex-direction: row;
  border: 1px solid #d7dfe9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 4px;
}
.custom-dd-height {
  height: 30px !important;
  width: 160px !important;
}
.custom-dropdown-menu {
  background: #ffffff;
  width: 100%;
  height: 144px;
  overflow-y: scroll;
  border: 1px solid #d7dfe9;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px rgba(232, 233, 245, 0.5);
  border-radius: 4px;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.form-control {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  color: #3f4247 !important;
}
.form-control-lg {
  min-height: 40px !important;
  padding: 0 1rem !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.custom-dropdown {
  height: 40px;
  width: 100%;
  background: #ffffff !important;
  box-shadow: none !important;
  border: 1px solid #d7dfe9 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.css-1rhbuit-multiValue div {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #0b69ff;
}
.css-1rhbuit-multiValue {
  background: rgba(11, 105, 255, 0.1) !important;
  border-radius: 2px !important;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 1px solid #000000;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 2px;
}
.input-title {
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #7e858e;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 3px;
  top: 1px;
  width: 3px;
  height: 7px;
  border: solid black;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.css-1pahdxg-control {
  background-color: hsl(0, 0%, 100%);
  border: 1px solid rgba(11, 105, 255, 0.5) !important;
  box-shadow: none !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
}
input::placeholder {
  font-family: Cairo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: #99a5b4;
}

input:focus,
input.form-control:focus,
textarea.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  border: 1px solid rgba(11, 105, 255, 0.5) !important;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #0b69ff !important;
  box-shadow: none !important;
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #b3b3b3 !important;
  box-shadow: none !important;
}
.table-body-text {
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 16px 0.5rem !important;
  letter-spacing: 0.1px;

  color: #3f4247;
}
a {
  transition: background 0.2s, color 0.2s;
}
a:hover,
a:focus {
  text-decoration: none;
}

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  box-shadow: inset 0px -1px 0px #d7dfe9;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

.sidebar-brand {
  position: absolute;
  top: 0;
  width: 250px;
  text-align: center;
  padding: 20px 0;
}
.sidebar-brand h2 {
  margin: 0;
  font-weight: 600;
  font-family: "Cairo", sans-serif !important;

  font-size: 24px;
  color: #fff;
}

.sidebar-nav {
  position: absolute;
  top: 75px;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-nav > li {
  margin: 10px 15px;
  line-height: 42px;
}
.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: #757575;
  font-weight: 600;
  padding: 1px 16px;
  font-family: "Cairo", sans-serif !important;

  font-size: 18px;
}
.toggled-sidebar {
  background: rgba(215, 223, 233, 0.5);
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  left: -8px;
  top: 3px;
}
.tab-content {
  padding-top: 24px;
}
.nav-tabs .nav-link {
  border-color: #f4f6fa #f4f6fa #dee2e6 !important;
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

// .nav-tabs .nav-link:focus,
// .nav-tabs .nav-link:hover {
//   border-color: #f4f6fa #f4f6fa #dee2e6 !important;
// }

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #333333;

  border-bottom-color: #299cf7 !important;
}
.sidebar-nav > li > a > div.toggled-hover:hover,
.sidebar-nav > li > a > div.active {
  background: rgba(11, 105, 255, 0.1);
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  left: -8px;
  top: 3px;
}
.sidebar-nav > li.hover > a:hover,
.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #0b69ff;
  font-weight: 900;
  background: rgba(11, 105, 255, 0.1);
  border-radius: 4px;
}
.sidebar-nav > li > a i.fa {
  font-size: 24px;
  font-family: "Cairo", sans-serif !important;

  width: 80px;
}

#navbar-wrapper {
  width: 100%;
  position: absolute;
  z-index: 2;
}
#wrapper.toggled #navbar-wrapper {
  position: absolute;
  margin-right: -250px;
}
#navbar-wrapper .navbar {
  border-width: 0 0 0 0;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #d7dfe9;
  font-size: 24px;
  padding: 16px 32px;
  font-family: "Cairo", sans-serif !important;

  margin-bottom: 0;
  border-radius: 0;
}
#navbar-wrapper .navbar a {
  color: #757575;
}
#navbar-wrapper .navbar a:hover {
  color: rgba(11, 105, 255, 0.1);
}
.modal-content {
  height: 100%;
  border-radius: 0 !important;
}
.modal-body {
  background: #f4f6fa !important;
  padding: 32px !important;
}
#myModal {
  position: absolute;
  right: 0;
  margin: 0;
  height: 100vh !important;
  width: 462px;
}
#content-wrapper {
  width: 100%;
  position: absolute;
  background: #f4f6fa;
  padding: 32px;
  top: 100px;
}
#wrapper.toggled #content-wrapper {
  position: absolute;
  margin-right: -250px;
}

@media (max-width: 992px) {
  .options-table {
    width: 100%;
  }
  .custom-modal-width .modal-content {
    width: 100% !important;
    height: 100vh;
    overflow: scroll;
  }
}

@media (min-width: 992px) {
  #wrapper {
    padding-left: 250px;
  }

  .search-input {
    width: 100%;
  }
  #wrapper.toggled {
    padding-left: 80px;
  }

  #sidebar-wrapper {
    width: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 80px;
  }

  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 80px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 80px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #wrapper {
    padding-left: 80px;
  }

  #sidebar-wrapper {
    width: 80px;
  }

  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}

@media (max-width: 767px) {
  #wrapper {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -250px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: 250px;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: 250px;
  }
}
